<template>
  <div class="portfolio_list_wrap">
    <div class="my_portfolio_setting">
      <button class="setting_btn" @click="movePage('/portfolio/edit')">{{ $t('portfolio_setting') }}</button><br>
    </div>
    <div class="portfolio_list_box" v-for="i in 3" :key="`portfolio${i}`">
      <div class="portfolio_list_img" v-for="j in 5" :key="`portfolio${j}`"></div>
      <div class="portfolio_list_date">
        <span>2021.08</span>
      </div>
      <div class="portfolio_list_text">
        <span>
          디지털 아트에 푹 빠진 아티스트경력 14년의 초보 디지털아티스트입니다. 작성한 내용이 들어갑니다. 작성한 내용이 들어갑니다. 작성한 내용이 들어갑니다.
          저에게 큐레이션을 원하시는 분은 010-0000-0000 으로 연락주세요.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "PortfolioListMyLayout",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>